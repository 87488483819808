



.bg {
    background: url('../../images/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 0 10px;
}
.swiperthumb .swiper-slide-thumb-active  {
    opacity: 1;
}
 .swiperthumb .swiper-wrapper{
    display: flex !important;
    width: 100% !important;
    justify-content: center !important;
    align-items: center !important;
 }

.paginationDS span {
    width: 10px !important; 
    cursor: pointer;
    height: 10px; 
    background: #252525; 
    border-radius: 100%; 
    margin: 0 5px;
    transition: width 0.3s ease; 
  } .paginationDS .swiper-pagination-bullet {
    width: 10px; 
    height: 10px; 
    background: #252525 !important; 
    border-radius: 100%; 
    margin: 0 5px;
    transition: width 0.3s ease; 
  
} .paginationDS .swiper-pagination-bullet-active {
    width: 30px !important;   
    height: 10px; 
    background: #474747 !important; 
    border-radius: 5px; 
  }
  .paginationDT span {
    width: 10px !important; 
    cursor: pointer;
    height: 10px; 
    background: #252525; 
    border-radius: 100%; 
    margin: 0 5px;
    transition: width 0.3s ease; 
  } .paginationDT .swiper-pagination-bullet {
    width: 10px; 
    height: 10px; 
    background: #252525 !important; 
    border-radius: 100%; 
    margin: 0 5px;
    transition: width 0.3s ease; 
  
} .paginationDT .swiper-pagination-bullet-active {
    width: 30px !important;   
    height: 10px; 
    background: #474747 !important; 
    border-radius: 5px; 
  }
.slick-arrow {
    display: none !important;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block ;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.dtcl .swiper-wrapper{
    display: flex !important;
    justify-content: center !important;
    justify-items: center !important;
    align-items: center !important;
}
.dtcl .swiper-slide {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    width: 200px !important;
    height: 200px !important;
    object-fit: contain;
}
.dtcl .swiper-slide-active{
    width: 328px !important;
    height: 328px !important;    
    object-fit: contain;
  }  

 .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: var(--swiper-pagination-bottom, 8px);
    top: var(--swiper-pagination-top, auto);
    left: 0;
    width: 100%;
    display: flex !important;
    justify-content: space-between !important;
}
@media screen and ( max-width: 768px) {
    /* .swiperdoitac .swiper-wrapper{
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .swiperdoitac .swiper-slide.swiper-slide-prev{
        width: 200px !important;
        height: 200px !important;
    }
    .swiperdoitac .swiper-slide.swiper-slide-next{
        width: 200px !important;
        height: 200px !important;
    }
    .swiperdoitac .swiper-slide.swiper-slide-active{
        width: 300px !important;
        height: 300px !important;
    }
     */
    
      .paginationDS span {
        width: 10px !important; 
        
        cursor: pointer;
        height: 10px; 
        background: #fff; 
        border-radius: 100%; 
        margin: 0 5px;
        transition: width 0.3s ease; 
       
      }
    
    .paginationDS .swiper-pagination-bullet {
        width: 10px; 
        height: 10px; 
        background: #cdcdcda7 !important; 
        border-radius: 100%; 
        margin: 0 5px;
        transition: width 0.3s ease; 
      
    }
    .paginationDS .swiper-pagination-bullet-active {
        width: 10px !important;   
        height: 10px; 
        background: #fff !important; 
        border-radius: 5px; 
      }
    
}
.swiper-pagination-bullet {
    width: 70px !important;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #fff !important;
    opacity: 1 !important;
    background: transparent !important;  
}
.swiper-pagination {
    position: absolute;
    top: 35px !important;
    left: 0 !important;
    width: 50% !important;
}
.paginationLM .swiper-pagination-bullet-active {
    border-top: 4px solid #ff9900 !important;
    border-radius: unset !important;     

}

.swiper-pagination-bullet-active {
    color: #ff9900 !important;
    background: transparent !important;
    font-weight: 900 !important;
}

.qr {
    border-radius: 13.943px;
    border: 0.734px solid #000;
    background: #E7E7E7;
}

.link {
    border-radius: 13.943px;
    border: 0.734px solid #000;
    background: #E7E7E7;
}

@media screen and (max-width: 1279.98px) {
    .swiper-pagination-bullet {
        width: 60px !important;
        font-size: 15px !important;
    }

    .swiper-pagination {
        text-align: start !important;
    }
}

@media screen and (max-width: 1023.98px) {
    .swiper-pagination {
        display: none !important;
        text-align: start !important;
    }
}