.ps-active {
    background-color: #ad97e3;
}

.ps-menu-button:hover {
    background-color: transparent !important;
}

.ant-input-group-addon button {
    background-color: #ad97e3 !important;
}