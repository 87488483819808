.txtcenter {
    text-align: center;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}
.pagination span {
    width: 10px !important; 
    cursor: pointer;
    height: 10px; 
    background: #252525; 
    border-radius: 100%; 
    margin: 0 5px;
    transition: width 0.3s ease; 
  }
.pagination .swiper-pagination-bullet {
    width: 10px; 
    height: 10px; 
    background: #252525 !important; 
    border-radius: 100%; 
    margin: 0 5px;
    transition: width 0.3s ease; 
  
}
.pagination .swiper-pagination-bullet-active {
    width: 30px !important;   
    height: 10px; 
    background: #474747 !important; 
    border-radius: 5px; 
  }

/* Brand logo slider */
.brands {
    position: relative;
    overflow: hidden;
    height: 10rem;
}
.bg-job{
    background-image: url('../../images//bg-content-job.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
   
}
.bg-jobmobi{
    background-image: url('../../images/bg-jobmobi.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
   
}
.brands:hover .brands__wrapper,
.brands:hover .brands__preWrapper {
    animation-play-state: paused;
}

.brands__preWrapper {
    position: relative;
    width: 100%;
    height: 12rem;
    transform: translateX(0%);
    animation: scroll1 110s linear infinite;
    /* animation-delay: 4s; */
    animation-direction: alternate;
    will-change: transform;
}

.brands__wrapper {
    position: absolute;
    white-space: nowrap;
    transform: translateX(0%) translateZ(0);
    animation: scroll2 110s linear infinite;
    /* animation-delay: 4s; */
    animation-direction: alternate;
    will-change: transform;
}

.brands__wrapper>a,
.brands__wrapper>div {
    position: relative;
    display: inline-block;
    margin: 0 1rem;
    vertical-align: middle;
}

/* .brands__wrapper>a img {
    max-width: 15rem;
    max-height: 8.5rem;
} */

@keyframes scroll1 {
    0% {
        transform: translateX(-0%);
    }

    100% {
        transform: translateX(100%);
    }
}

@keyframes scroll2 {
    0% {
        transform: translateX(0%) translateZ(0);
    }

    100% {
        transform: translateX(-100%) translateZ(0);
    }
}

.bg-word {
    background: url('../../images/bg-word.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.bg-word-mb {
    background: url('../../images/bg-vuong.png');
    background-repeat: no-repeat;
    background-size: contain;
}