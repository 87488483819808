.bg-profile {
    background: url('../../images/bginfo.png') !important;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
}

.avatar {
    border-radius: 356.858px;
    border: 11.895px solid #FFFFFFa5;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.25);
}

.tele {
    background: url('../../images/tele.png');
    background-repeat: no-repeat;
    background-size: contain;
}
.zalo {
    background: url('../../images/zalo.png');
    background-repeat: no-repeat;
    background-size: contain;
}
.facebook {
    background: url('../../images/face.png');
    background-repeat: no-repeat;
    background-size: contain;
}
.youtube { 
    background: url('../../images/youtube.png');
    background-repeat: no-repeat;
    background-size: contain;
}
.tiktok {

    background: url('../../images/tiktok.png');
    background-repeat: no-repeat;
    background-size: contain;
}
.gmail {
    background: url('../../images/google.png');
    background-repeat: no-repeat;
    background-size: contain;
}
@media screen and (max-width:768px) { 
    .bg-profile {
        background: url('../../images/bg-mobile.png') !important;
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: contain;
       
    }
    .tele{
        background: url('../../images/telemobi.png');
    background-repeat: no-repeat;
    background-size: contain;
    }
    .zalo{
     background: url('../../images/zalomobile.png');
    background-repeat: no-repeat;
    background-size: contain;
    }
    .facebook{
    background: url('../../images/facebookmobile.png');
    background-repeat: no-repeat;
    background-size: contain;
    }
    .youtube{
        background: url('../../images/youtubemobile.png');
        background-repeat: no-repeat;
    background-size: contain;
    }
    .tiktok{
        background: url('../../images/tiktokmobile.png');
        background-repeat: no-repeat;
    background-size: contain;
    }
    .gmail{
        background: url('../../images/googlemobile.png');
        background-repeat: no-repeat;
    background-size: contain;
    }
}



