@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Cafeta';
  src: url('../public/fonts/UTM-Cafeta.ttf');  
  font-weight: 100;
  font-weight: 200;
  font-weight: 300;
  font-weight: 400;
  font-weight: 500;
  font-weight: 600;
  font-weight: 700;
  font-weight: 800;
  font-weight: 900;
  font-weight: bold;
  font-weight: bolder;
  font-weight: lighter;
  font-weight: normal;
  font-style: normal;
}

  /* :where(.css-dev-only-do-not-override-vryruh).ant-modal .ant-modal-content {
    position: relative;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
    padding: 10px !important;
} */