

.daisuthuonghieu .swiper-slide{
    width: 300px !important;
    height: 590px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    transform: scale(0.9);
 } 
 

 .daisuthuonghieu .swiper-slide img  {
    width: 1050px !important;
    height: 590px !important;
    min-height: 590px !important;
    object-fit: cover !important;
 }
 .daisuthuonghieu .swiper-wrapper{
    display: flex !important;
    align-items: center !important;
 }
 .daisuthuonghieu .swiper-slide-active{
    height: 590px !important;
    transform:  scale(1) !important;
 }
 @media screen and (max-width: 768px) {
    .daisuthuonghieumobi .swiper-slide{
        padding: 5px !important;    
    }
    .daisuthuonghieumobi .swiper-slide img{
        border-radius: 10px !important;   
        display: block;
        margin: 0 auto !important;
    }
    .daisuthuonghieu .swiper-slide {
        width: 300px !important;
        height: 350px !important;
     }
    .daisuthuonghieu .swiper-slide {
        /* width: 100% !important; */
        height: 350px !important;
    }
    .daisuthuonghieu .swiper-slide img {
        width: 100% !important;
        height: 350px !important;
        object-fit: contain !important;
    }
    .daisuthuonghieu .main img{
        width: 100% ;
    }
    
 }
 .daisuthuonghieupagination span {
    width: 20px !important;
    height: 3px !important;    
    background: #ffffffa5 !important;  
    border-radius: 2px !important;

 }
 /* .daisuthuonghieupagination .swiper-pagination-bullet {

    text-align: center;
    color: #fff !important;
    opacity: 1 !important;
    background: #fff !important;  
} */
 .daisuthuonghieupagination .swiper-pagination-bullet-active {
    background: #ff9900 !important;
    width: 40px !important;
    transition: width .5s ease-in-out;

}