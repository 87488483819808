
.bg-home{
    background-image: url('../../images/bg-home.png') !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
}
.slidedaisu .swiper-wrapper{
    display: flex !important;
    width: 100% !important;
    align-items: center !important;
}
.slidedaisu .swiper-slide{
    display: flex !important;
    width: 300px !important;    
    align-items: center !important;
    transform: scale(0.9);
}
.slidedaisu .swiper-slide-active{
    width: 320px !important;
    height: 300px !important;
    transform: scale(1.010);
} 
.slidedaisu .swiper-slide-active img{
    width: 310px !important;
    height: 300px !important;
    transform: scale(1);
    object-fit: contain !important;
} 

@media screen and (max-width:768px) {    
   
    .bg-home{
        background-image: none !important;
    }
    .bg-mobile{
        background-image: url('../../images/bg-homem.png') !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
    }
}
