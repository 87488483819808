.swiperdoitac .swiper-wrapper{
    display: flex;
    align-items: center;
}
.swiperdoitac .swiper-slide{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    object-fit: contain !important;
}
.swiperdoitac .swiper-slide img{
    width: 150px;
    height: 150px;
    object-fit: contain !important;
}
.swiperdoitac .swiper-slide-active{
    width: 250px;
    height: 250px;
}
.swiperdoitac .swiper-slide-active img{
    width: 250px;
    height: 250px;
}

