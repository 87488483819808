.ant-row {
    flex-direction: column;
}

.test svg {
    min-height: 100vh;
}

.ant-col-offset-8 {
    margin: 0;
}

.ant-form-item-control {
    min-width: 360px !important;
}

.ant-form-item-label {
    text-align: start !important;
}

.ant-col {
    min-height: 20px !important;
}

.box {
    box-shadow: rgba(126, 67, 163, 0.25) 0px 30px 60px -12px inset, rgba(110, 47, 129, 0.3) 0px 18px 36px -18px inset;
}

.box-sha {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.bg-login svg {
    min-height: 100vh;
}

.boy svg {
    min-height: 100vh;
}

.ant-form-horizontal .ant-form-item-control {
    flex: 1;
}